<template>
  <div>

    <v-row class="mb-1">
      <v-col cols="12" lg="6">
        <v-select
                id="key_9"
                v-model="filter.sum"
                :items="sums"
                :label="keyword.key_9"
                class="rounded mb-1"
                clearable
                dense
                filled
                hide-details
                item-text="name"
                item-value="value"
                rounded/>
      </v-col>
      <v-col cols="12" lg="6">
        <v-select
                id="key_1"
                v-model="filter.types"
                :items="filter_elements.types"
                :label="keyword.key_1"
                chips
                class="rounded mb-1"
                deletable-chips
                dense
                filled
                hide-details
                item-text="name"
                item-value="name"
                multiple
                rounded
                small-chips/>
      </v-col>
      <v-col cols="12" lg="6">
        <v-select
                id="key_2"
                v-model="filter.regions"
                :items="filter_elements.regions"
                :label="keyword.key_2"
                chips
                class="rounded mb-1"
                deletable-chips
                dense
                filled
                hide-details
                item-text="name"
                item-value="name"
                multiple
                rounded
                small-chips/>
      </v-col>
      <v-col cols="12" lg="6">
        <v-autocomplete
                id="key_3"
                v-model="filter.cities"
                :items="cities"
                :label="keyword.key_3"
                chips
                class="rounded mb-1"
                deletable-chips
                dense
                filled
                hide-details
                item-text="name"
                item-value="name"
                multiple
                rounded
                small-chips>
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle" @mousedown.prevent>
              <v-list-item-action>
                <v-icon :color="filter.cities.length > 0 ? 'primary' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Sélectionner tout
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="6">
        <v-select
                id="key_4"
                v-model="filter.municipalities"
                :items="filter_elements.municipalities"
                :label="keyword.key_4"
                chips
                class="rounded mb-1"
                deletable-chips
                dense
                filled
                hide-details
                item-text="name"
                item-value="name"
                multiple
                rounded
                small-chips/>
      </v-col>
      <v-col cols="12" lg="6">
        <v-select
                id="key_8"
                v-model="filter.classes"
                :items="filter_elements.classes"
                :label="keyword.key_8"
                chips
                class="rounded mb-1"
                deletable-chips
                dense
                filled
                hide-details
                item-text="name"
                item-value="name"
                multiple
                rounded
                small-chips/>
      </v-col>
    </v-row>

    <div class="d-flex justify-space-between">

      <v-spacer/>

      <v-btn :loading="loading"
             color="blue"
             dark
             depressed
             @click="onFilter">
        <v-icon left>mdi-magnify</v-icon>
        Recherche
      </v-btn>

    </div>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
   data() {
      return {
         loading: false,
         salepointIds: [],
         filter: {
            cities: [],
         },
         sums: [
            {name: this.$store.state.keyword.key_10, value: 'sum_of_M_106'},
            {name: this.$store.state.keyword.key_11, value: 'sum_of_M_70'},
            {name: this.$store.state.keyword.key_12, value: 'sum_of_ULT'},
            {name: this.$store.state.keyword.key_13, value: 'sum_of_HSP'},
            {name: this.$store.state.keyword.key_14, value: 'sum_of_Con92_1'},
            {name: this.$store.state.keyword.key_15, value: 'sum_of_Con92_2'},
            {name: this.$store.state.keyword.key_16, value: 'sum_of_OV_GM_1'},
            {name: this.$store.state.keyword.key_17, value: 'sum_of_OV_GM_2'},
            {name: this.$store.state.keyword.key_18, value: 'sum_of_OV_PM'},
            {name: this.$store.state.keyword.key_19, value: 'sum_of_Disps'},
            {name: this.$store.state.keyword.key_20, value: 'sum_of_Waf_66'},
            {name: this.$store.state.keyword.key_21, value: 'sum_of_Waf_99'},
            {name: this.$store.state.keyword.key_22, value: 'sum_of_M_M'},
            {name: this.$store.state.keyword.key_23, value: 'sum_of_M_C1'},
            {name: this.$store.state.keyword.key_24, value: 'sum_of_M_C2'},
            {name: this.$store.state.keyword.key_25, value: 'sum_of_M_C3'},
         ]
      }
   },
   methods: {
      onFilter() {
         this.loading = true
         this.$Progress.start()
         HTTP.get('/campaigns/salepoints/filter', {
            params: {
               filter: this.filter
            }
         }).then((res) => {
            this.loading = false
            this.$Progress.finish()
            this.salepointIds = res.data.data
            this.$emit('set-salepoint-ids', this.salepointIds)
         }).catch(err => {
            this.$Progress.fail()
            this.loading = false
            console.log(err)
         })
      },
      toggle() {
         if (this.filter.cities.length === this.cities.length) {
            this.filter.cities = []
         } else {
            this.filter.cities = this.cities.map(el => el.name)
         }
      },
   },
   computed: {
      filter_elements() {
         return this.$store.state.salepoint_filter_elements
      },
      cities() {
         return this.$store.state.cities
      },
      keyword() {
         return this.$store.state.keyword
      },
      icon() {
         if (this.filter.cities.length === this.cities.length) return 'mdi-close-box'
         if (this.filter.cities.length > 0 && this.filter.cities.length !== this.cities.length) return 'mdi-minus-box'
         return 'mdi-checkbox-blank-outline'
      },
   }
}
</script>

<style scoped>

</style>