<template>
  <div>
    <v-dialog max-width="1000"
              v-model="dialog"
              persistent
              scrollable>
      <v-card>

        <v-card-title>
          Séléctionnez des produits
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-6">

          <label>Filtrer par marque :</label>
          <v-chip-group v-model="filterBrand"
                        column
                        multiple
                        @change="filterProduct">
            <v-chip filter
                    color="blue"
                    outlined
                    v-for="brand in fBrands"
                    :key="brand.id"
                    :value="brand.id">
              {{ brand.name }}
            </v-chip>
          </v-chip-group>

          <label>Filtrer par catégories :</label>
          <v-chip-group v-model="filterCategory"
                        column
                        multiple
                        @change="[filterProduct(),filterAllSubCategory()]">
            <v-chip filter
                    color="blue"
                    outlined
                    v-for="cat in fCategories"
                    :key="cat.id"
                    :value="cat.id">
              {{ cat.name }}
            </v-chip>
          </v-chip-group>

          <div class="mt-4">

            <v-row>
              <v-col></v-col>
              <v-col></v-col>
              <v-col class="text-right">
                <v-text-field dense
                              hide-details
                              prepend-inner-icon="mdi-magnify"
                              filled
                              rounded
                              v-model.trim="searchProduct"
                              placeholder="Recherche..."></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center" v-if="fProducts.length===0">
              <v-col cols="4" class="">
                <v-img :src="require('@/assets/list.svg')" width="250"/>
                <p>Aucun élement correspondant trouvé !</p>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="3" v-for="item in fProducts" :key="item.id" class="d-flex">

                <v-card outlined
                        @click="change(item)"
                        class="flex flex-column"
                        :dark="productIds.includes(item.id)"
                        :color="productIds.includes(item.id) ? 'primary' : ''">

                  <v-img v-if="item.photo" :src="FILE_URL + item.photo" height="200"/>
                  <v-img v-else :src="require('@/assets/no-image.png')" height="200"/>

                  <div class="text-center pa-2">

                    <span>{{ item.name }}</span>

                    <strong class="d-block mt-2"
                            :class="productIds.includes(item.id) ? 'white--text' :'blue--text'">
                      <span class="text-decoration-underline">Code</span>
                      <br>
                      {{ item.code }}
                    </strong>
                  </div>
                </v-card>

              </v-col>
            </v-row>

            <v-divider class="my-5"/>
            <v-row align="center" justify="center">
              <v-col cols="4">
                <v-alert color="blue" type="info" dark>
                  Produits sélectionnés : {{ productIds.length }}
                </v-alert>
              </v-col>
              <v-col class="text-right">
                <v-btn color="primary" :disabled="!productIds.length" class="rounded-lg"
                       depressed @click="close">
                  Valider
                  <v-icon right>mdi-check</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['dialog', 'taskIndex', 'tasks'],
  data() {
    return {
      products: this.$store.state.products,
      fProducts: [],
      fCategories: [],
      fBrands: [],
      filterBrand: [],
      filterCategory: [],
      searchProduct: '',
      productIds: [],
      index: '',
      FILE_URL: process.env.VUE_APP_FILE_URL
    }
  },
  methods: {
    validate() {
      this.close()
    },
    close() {
      this.$emit('update:dialog', false)
    },
    change(el) {
      let checkExist = this.productIds.includes(el.id)
      if (checkExist) {
        let index = this.productIds.findIndex(item => item === el.id)
        this.$delete(this.productIds, index)
        let indexAll = this.question.conditions.findIndex(item => item.id === el.id)
        this.$delete(this.question.conditions, indexAll)
      } else {
        this.productIds.unshift(el.id)
        this.question.conditions.unshift({
          id: el.id,
          photo: el.photo,
          code: el.code,
          name: el.name,
          is_box: false,
          quantity: 1,
        })
      }
    },
    setSelectedItems(i) {
      this.index = i
      this.productIds = []
      let products = this.question.conditions;
      if (products.length > 0) {
        products.forEach(el => {
          this.productIds.push(el.id)
        })
      }
    },
    filterProduct() {
      this.fProducts = this.products
      this.fCategories = this.categories
      this.fBrands = this.brands

      if (this.filterBrand.length > 0) {
        this.fProducts = this.fProducts.filter(item => this.filterBrand.includes(item.brand_id))
        let categoryIds = []
        this.fProducts.forEach(item => {
          categoryIds.push(item.category_id)
        })
        categoryIds = categoryIds.filter(function (item, pos, self) {
          return self.indexOf(item) === pos;
        })
        this.fCategories = this.fCategories.filter(item => categoryIds.includes(item.id))
      }

      if (this.filterCategory.length > 0) {
        this.fProducts = this.fProducts.filter(item => this.filterCategory.includes(item.category_id))
        let brandIds = []
        this.fProducts.forEach(item => {
          brandIds.push(item.brand_id)
        })
        brandIds = brandIds.filter(function (item, pos, self) {
          return self.indexOf(item) === pos;
        })
        this.fBrands = this.fBrands.filter(item => brandIds.includes(item.id))
      }
    },
    getProducts() {
      this.fProducts = this.products
    },
  },
  watch: {
    searchProduct(keyword) {
      this.filterProduct()
      if (keyword !== null) {
        this.fProducts = this.fProducts.filter((el) => {
          return el.name.toLowerCase().includes(keyword.toLowerCase())
        })
      }
    }
  },
  created() {
    this.getProducts()
    this.fCategories = this.categories
    this.fBrands = this.brands
  },
  computed: {
    categories() {
      return this.$store.state.categories
    },
    brands() {
      return this.$store.state.brands
    },
    question() {
      return this.tasks[this.taskIndex].questions[0]
    },
  }
}
</script>

<style scoped>

</style>