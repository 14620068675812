<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-card-text>
        <v-row justify="center">
          <v-col class="text-right">
            <v-btn class="rounded-lg" color="blue" dark
                   depressed
                   @click="openProductsDialog">
              <v-icon left>mdi-plus</v-icon>
              Selectionnez les Produits
            </v-btn>
          </v-col>
          <v-col class="text-center" cols="12">

            <v-text-field v-model.trim="question.description"
                          class="mb-2" dense
                          filled
                          hide-details
                          label="Description *"></v-text-field>

            <v-text-field v-model.trim="question.description_ar"
                          class="ar rtl mb-2"
                          dense filled
                          hide-details
                          label="* الوصف"></v-text-field>

            <v-alert text type="info">
              <strong>Remarque : </strong> {{ response }}
            </v-alert>
            <div v-if="question.conditions.length === 0" class="text-center">
              <v-avatar size="250" tile>
                <v-img :src="require('@/assets/list.svg')"/>
              </v-avatar>
            </div>
            <v-simple-table v-else class="table-border">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Image
                  </th>
                  <th class="text-left">
                    Code
                  </th>
                  <th class="text-left">
                    Produit
                  </th>
                  <th class="text-left">
                    Box
                  </th>
                  <th class="text-left">
                    Qunatité
                  </th>
                  <th class="text-right">
                    Action
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(condition,i) in question.conditions" :key="i">
                  <td class="text-left">
                    <v-img v-if="condition.photo" :src="FILE_URL + condition.photo"
                           class="rounded-circle"
                           height="40" width="40"/>
                    <v-img v-else :src="require('@/assets/no-image.png')"
                           class="rounded-circle"
                           height="40" width="40"/>
                  </td>
                  <td class="text-left">{{ condition.code }}</td>
                  <td class="text-left">{{ condition.name }}</td>
                  <td class="text-left">
                    <v-switch v-model="condition.is_box" dense></v-switch>
                  </td>
                  <td class="text-left">
                    <div class="d-flex">
                      <v-btn depressed fab x-small
                             @click="decrementCondition(i)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <span class="mx-2 mt-1">
                       {{ condition.quantity }}
                     </span>
                      <v-btn color="primary" depressed fab x-small
                             @click="incrementCondition(i)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </td>
                  <td class="text-right">
                    <v-btn icon @click="removeCondition(i)">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--Dialogs-->
    <ProductsDialog ref="productsDialog"
                    :dialog.sync="productsDialog"
                    :task-index.sync="taskIndex"
                    :tasks.sync="tasks"/>
  </div>
</template>

<script>


import ProductsDialog from "../Dialogs/ProductsDialog";

export default {
    components: {ProductsDialog},
    props: ['taskIndex', 'response', 'tasks'],
    data() {
        return {
            productsDialog: false,
            FILE_URL: process.env.VUE_APP_FILE_URL
        }
    },
    methods: {
        openProductsDialog() {
            this.productsDialog = true
            this.$refs.productsDialog.setSelectedItems(this.taskIndex)
        },

        decrementCondition(i) {
            let data = {
                taskIndex: this.taskIndex,
                conditionIndex: i
            }
            if (this.tasks[data.taskIndex].questions[0].conditions[data.conditionIndex].quantity > 1)
                this.tasks[data.taskIndex].questions[0].conditions[data.conditionIndex].quantity -= 1
        },
        incrementCondition(i) {
            let data = {
                taskIndex: this.taskIndex,
                conditionIndex: i
            }
            this.tasks[data.taskIndex].questions[0].conditions[data.conditionIndex].quantity += 1
        },
        removeCondition(i) {
            let data = {
                taskIndex: this.taskIndex,
                conditionIndex: i
            }
            this.tasks[data.taskIndex].questions[0].conditions.splice(data.conditionIndex, 1)
        }
    },
    computed: {
        products() {
            return this.tasks[this.taskIndex].condition.products
        },
        question() {
            return this.tasks[this.taskIndex].questions[0]
        },
    }
}
</script>

<style scoped>

</style>