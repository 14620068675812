<template>
  <div>

    <v-avatar tile size="220">
      <v-img :src="require('@/assets/list.svg')"></v-img>
    </v-avatar>

    <h2 class="mt-3">
      La liste est vide !!
    </h2>

    <p class="my-3 font-weight-medium fs-16">
      Aucun enregistrement correspondant trouvé.
    </p>

  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>