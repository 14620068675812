<template>
  <div>
    <v-row class="mb-1">
      <v-col cols="12">

        <v-alert border="left" dense prominent text type="info">
          <strong>Remarque : </strong> Le premier colomn c'est le code POS
        </v-alert>

        <v-file-input
                ref="excel_file"
                v-model="excel_file"
                :error-messages="errors.excel_file"
                append-icon="mdi-file-excel"
                class="rounded"
                clearable
                dense
                filled
                hide-details="auto"
                label="Sélectionnez un fichier excel *"
                rounded
                show-size
                small-chips
                @change="uploadFile"
                @click="errors && errors.excel_file ? errors.excel_file = '' : false"/>
      </v-col>
    </v-row>

    <div class="d-flex justify-space-between">

      <v-spacer/>

      <v-btn :loading="loading"
             color="blue"
             dark
             depressed
             @click="onFilter">
        <v-icon left>mdi-magnify</v-icon>
        Recherche
      </v-btn>

    </div>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
   data() {
      return {
         loading: false,
         excel_file: null,
         salepointIds: [],
         errors: [],
      }
   },
   methods: {
      uploadFile(file) {
         if (file) {
            this.excel_file = file
         }
      },
      onFilter() {
         this.errors = []
         this.loading = true
         this.$Progress.start()
         let formData = new FormData()
         formData.append('excel_file', this.excel_file)
         HTTP.post('/campaigns/salepoints/filter-by-excel', formData).then((res) => {
            this.loading = false
            this.$Progress.finish()
            this.salepointIds = res.data.data
            this.$emit('set-salepoint-ids', this.salepointIds)
         }).catch(err => {
            this.$Progress.fail()
            this.loading = false
            this.errors = err.response.data.errors
            this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
            console.log(err)
         })
      }
   }
}
</script>

<style scoped>

</style>