<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="center">

        <v-col cols="12" lg="8">
          <v-toolbar class="rounded-lg transparent" flat>
            <v-tabs v-model="active_tab">
              <v-tab class="b-700" disabled>
                <v-skeleton-loader v-if="!campaign" type="button"/>
                <span v-else>
                <span class="text--primary">Campagne : </span>   {{ campaign.name }}
                </span>
              </v-tab>
              <v-tab :disabled="!campaign" class="b-700">
                <v-icon left>mdi-plus</v-icon>
                Ajouter des tâches
              </v-tab>
            </v-tabs>
            <v-spacer/>
            <v-btn class="mt-3" color="primary"
                   depressed
                   rounded
                   @click="$router.push('/campaigns')">
              <v-icon left>mdi-arrow-left</v-icon>
              Retour
            </v-btn>
          </v-toolbar>
        </v-col>

        <v-col cols="12" lg="8">
          <v-alert :value="isError" dismissible prominent text type="error">
            <ul>
              <li v-for="(error,i) in errors" :key="i">{{ error[0] }}</li>
            </ul>
          </v-alert>
          <v-card v-for="(task,i) in tasks" :key="i" class="rounded-lg shadow mb-6">
            <v-btn v-if="numErrors.includes(i+1)"
                   absolute
                   color="error" dark depressed fab left small top>
              <v-icon>mdi-alert</v-icon>
            </v-btn>
            <v-btn absolute color="blue" dark depressed fab right small top>
              {{ i + 1 }}
            </v-btn>
            <v-card-text class="pa-10">

              <v-row>
                <v-col>
                  <v-text-field v-model.trim="task.name" label="Nom *"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model.trim="task.name_ar"
                                class="ar rtl"
                                label="الإ سم *"></v-text-field>
                </v-col>
              </v-row>

              <v-menu
                      ref="menu"
                      v-model="task.dateMenu"
                      :close-on-click="false"
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="task.dates.join(' ~ ')"
                                append-icon="mdi-calendar" label="Planification *" readonly
                                v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker v-model="task.dates"
                               :min="date_now" range scrollable>
                  <v-spacer></v-spacer>
                  <v-btn class="rounded-lg" text
                         @click="task.dateMenu = false">
                    <v-icon left>mdi-close-circle-outline</v-icon>
                    Fermer
                  </v-btn>
                  <v-btn :disabled="task.dates.length < 2" class="rounded-lg"
                         color="primary"
                         text
                         @click="task.dateMenu = false">
                    <v-icon left>mdi-check-circle-outline</v-icon>
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field v-model.trim="task.winning_points"
                            label="Points gagnants*"
                            suffix="Pts"></v-text-field>

              <v-file-input :clearable="false"
                            :disabled="task.upload_loading"
                            :error-messages="task.upload_errors && task.upload_errors.photo ? task.upload_errors.photo : ''"
                            :loading="task.upload_loading"
                            accept="image/png,image/jpeg"
                            append-icon="mdi-image-outline"
                            chips label="Photo" prepend-icon=""
                            show-size
                            small-chips
                            @change="uploadPhoto(i,$event)"
                            @click="task.upload_errors && task.upload_errors.photo ? task.upload_errors.photo = '' : false"></v-file-input>

              <div v-if="task.photo"
                   :class="$vuetify.theme.dark ? 'grey' : 'grey lighten-3'"
                   class="d-flex justify-space-between pa-3 rounded"
                   style="margin-top:-10px">
                <v-avatar size="40"
                          @click="[image = [{src: FILE_URL + task.photo}],visible = true]">
                  <v-img :src="FILE_URL + task.photo" class="rounded cursor-pointer"/>
                </v-avatar>
                <v-spacer/>
                <v-icon color="success">mdi-check-circle</v-icon>
              </div>


              <v-textarea v-model.trim="task.description"
                          hide-details
                          label="Description"
                          rows="2"></v-textarea>

              <v-textarea v-model.trim="task.description_ar"
                          class="ar rtl"
                          hide-details
                          label="* الوصف"
                          rows="2"></v-textarea>

              <div class="d-flex justify-space-between mt-5">
                <v-btn :disabled="!task.name"
                       class="mr-1 rounded-lg white--text"
                       color="blue"
                       depressed
                       @click="openQuestionsDialog(i)">
                  <v-icon left>mdi-file-question-outline</v-icon>
                  Affecter les tâches
                </v-btn>

                <v-spacer/>

                <v-chip :disabled="!task.name" class="text-uppercase"
                        color="blue"
                        dark
                        @click="openSalepointDialog(i)">
                  <v-icon left>mdi-map-marker-outline</v-icon>
                  {{ task.salepoint_ids.length }} - POS Affectées
                </v-chip>
              </div>

            </v-card-text>

            <v-divider/>
            <v-card-actions>
              <v-spacer/>
              <v-tooltip
                      v-if="tasks.length > 1"
                      bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                          icon
                          v-bind="attrs" @click="removeTask(i)" v-on="on">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                          icon
                          v-bind="attrs" @click="duplicateTask(task)" v-on="on">
                    <v-icon>mdi-content-duplicate</v-icon>
                  </v-btn>
                </template>
                <span>Dupliquer</span>
              </v-tooltip>
            </v-card-actions>

          </v-card>

          <div class="text-center">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary" dark
                        depressed
                        fab small v-bind="attrs" @click="addTask" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Ajouter une tâche</span>
            </v-tooltip>
          </div>

          <div class="d-flex justify-space-between mt-3">
            <v-spacer/>
            <v-btn :disabled="isLoading"
                   :loading="isLoading"
                   class="rounded-lg"
                   color="primary"
                   depressed
                   @click="validate()">
              <v-icon left>mdi-content-save-outline</v-icon>
              Enregistrer
            </v-btn>
          </div>

        </v-col>
      </v-row>
    </v-container>

    <!-- Dialogs-->
    <vue-easy-lightbox :imgs="image"
                       :index="index"
                       :visible="visible"
                       escDisabled
                       moveDisabled
                       @hide="visible = false"/>

    <SalepointsDialog
            ref="salepointsDialog"
            :dialog.sync="salepointsDialog"
            :task-index.sync="taskIndex"
            :tasks.sync="tasks"
    />

    <QuestionsDialog
            ref="questionDialog"
            :dialog.sync="questionDialog"
            :task-index.sync="taskIndex"
            :tasks.sync="tasks"/>

    <v-dialog
            v-model="validateDialog"
            max-width="400" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">
          <v-icon color="primary" size="80">mdi-information-outline</v-icon>
          <h2 class="text--primary mt-3">CONFIRMATION</h2>
          <p class="mt-5 mb-5">Voulez-vous vraiment ENRIGISTRER cette campagne ?</p>
          <v-btn class="rounded-lg mr-2" text
                 @click="validateDialog = false">
            <v-icon left>mdi-close</v-icon>
            Annuler
          </v-btn>
          <v-btn :loading="saveLoading" class="rounded-lg"
                 color="primary"
                 depressed @click="save">
            <v-icon left>mdi-content-save-outline</v-icon>
            Enrigistrer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import SalepointsDialog from "./Dialogs/SalepointsDialog";
import QuestionsDialog from "./Dialogs/QuestionsDialog";

export default {
    components: {QuestionsDialog, SalepointsDialog},
    data() {
        return {
            campaign: '',
            active_tab: '',
            loading: false,

            taskIndex: 0,
            salepointsDialog: false,
            questionDialog: false,
            validateDialog: false,
            saveLoading: false,

            FILE_URL: process.env.VUE_APP_FILE_URL,
            date_now: this.$moment().format('YYYY-MM-DD'),

            tasks: [{
                name: '',
                name_ar: '',
                description: '',
                description_ar: '',
                winning_points: '',
                photo: '',

                upload_loading: false,
                upload_errors: [],

                dates: [],
                dateMenu: false,
                salepoint_ids: [],
                task_model_id: 1,
                question_type_key: '',
                questions: [
                    {
                        question_type_key: 'image',
                        question_type_id: 1,
                        answer_type_id: 1,
                        content: '',
                        upload_loading: false,
                        is_video: false,
                        is_none: true,
                        description: '',
                        description_ar: '',
                        conditions: [],
                        options: [],
                    }
                ],
            }],

            //Show image
            image: '',
            visible: false,
            index: 0,

            //validations
            isError: false,
            isLoading: false,
            errors: '',
            numErrors: [],

        }
    },
    methods: {
        validate() {
            this.isLoading = true
            this.isError = false
            this.$Progress.start()
            HTTP.post('/campaigns/validate/tasks', {
                tasks: this.tasks
            }).then(() => {
                this.isLoading = false
                this.isError = false
                this.$Progress.finish()
                this.validateDialog = true
            }).catch(err => {
                this.isLoading = false
                this.isError = true
                this.$Progress.fail()
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                this.errors = err.response.data.errors
                this.numErrors = err.response.data.numErrors
                this.$vuetify.goTo(0)
                console.log(err)
            })
        },
        uploadPhoto(i, file) {
            this.tasks[i].upload_loading = true
            this.tasks[i].upload_errors = []
            let formData = new FormData()
            formData.append('photo', file)
            HTTP.post('/campaigns/upload-image', formData).then((res) => {
                this.tasks[i].upload_loading = false
                this.tasks[i].photo = res.data.file_name
            }).catch(err => {
                this.tasks[i].upload_loading = false
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                this.tasks[i].upload_errors = err.response.data.errors
                console.log(err)
            })
        },
        showCampaign() {
            HTTP.get('/campaigns/show/' + this.$route.params.id).then(res => {
                this.campaign = res.data.data
                this.active_tab = 1
            }).catch(err => {
                console.log(err)
            })
        },
        addTask() {
            let data = {
                name: '',
                name_ar: '',
                description: '',
                description_ar: '',
                winning_points: '',
                photo: '',

                upload_loading: false,
                upload_errors: [],

                dates: [],
                dateMenu: false,
                salepoint_ids: [],
                task_model_id: 1,
                question_type_key: '',
                questions: [
                    {
                        question_type_key: 'image',
                        question_type_id: 1,
                        answer_type_id: 1,
                        content: '',
                        upload_loading: false,
                        is_video: false,
                        is_none: false,
                        description: '',
                        description_ar: '',
                        conditions: [],
                        options: [],
                    }
                ],
            }
            this.tasks.push(data)
            this.$vuetify.goTo(document.body.scrollHeight - 222)
        },
        duplicateTask(data) {
            this.tasks.push(JSON.parse(JSON.stringify(data)))
            this.$vuetify.goTo(document.body.scrollHeight - 222)
        },
        removeTask(i) {
            this.tasks.splice(i, 1)
        },
        openSalepointDialog(i) {
            this.taskIndex = i
            this.salepointsDialog = true
            let salepointIds = this.tasks[i].salepoint_ids
            this.$refs.salepointsDialog.setSalepointIds(salepointIds)
        },
        openQuestionsDialog(i) {
            this.taskIndex = i
            this.questionDialog = true
        },
        save() {
            let data = this.tasks
            this.saveLoading = true
            HTTP.post('/campaigns/add-tasks', {
                campaign_id: this.campaign.id,
                tasks: data,
            }).then(() => {
                this.saveLoading = false
                this.$successMessage = 'Cette tâche a été enregistrée avec succès !'
                this.$router.push('/campaigns')
            }).catch(err => {
                this.saveLoading = false
                console.log(err)
            })
        },
    },
    created() {
        this.showCampaign()
    }
}
</script>

<style scoped>

</style>