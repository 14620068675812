<template>
  <div>
    <v-skeleton-loader v-if="loading" type="article,article,actions"/>

    <v-card-text v-if="!loading && salepoints.length === 0"
                 class="text-center">
      <NoResult/>
    </v-card-text>

    <div v-if="!loading && salepoints.length > 0">

      <div class="d-flex justify-space-between align-center align-content-center pa-4 mb-1 font-weight-bold bg-blue-lighten">
        <span class="blue--text">Total POS : {{ total }}</span>
        <v-spacer/>
        <span class="blue--text">
               <v-icon class="mt--4">mdi-check-circle-outline</v-icon>
                   Total POS Sélectionnés : {{ task.salepoint_ids.length }}
               </span>
      </div>


      <v-card-actions>
        <div class="d-flex align-center">
          <v-select v-model="per_page"
                    :items="[10,20,50,100,200,300,400,500]"
                    :style="{width: '120px'}"
                    dense
                    hide-details
                    label="Ligne par page"
                    outlined></v-select>

          <v-btn class="ml-2" depressed
                 @click="reset">
            <v-icon left>mdi-table-refresh</v-icon>
            Réinitialiser
          </v-btn>

        </div>
        <v-spacer/>
        <v-pagination v-if="total>per_page"
                      v-model="pagination.current"
                      :length="pagination.total"
                      circle
                      total-visible="6"
                      @input="onPageChange"
        ></v-pagination>
      </v-card-actions>

      <v-data-table v-model="selected"
                    :headers="headers"
                    :items="salepoints"
                    checkbox-color="success"
                    class="table-border"
                    disable-pagination
                    hide-default-footer
                    item-key="id"
                    show-select
                    @item-selected="selectRow"
                    @toggle-select-all="selectAllRow">

        <template v-slot:item.photo="{ item }">
          <v-avatar size="38">
            <v-img v-if="item.salepoint_account && item.salepoint_account.photo"
                   :src="FILE_URL + item.salepoint_account.photo"></v-img>
            <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
          </v-avatar>
        </template>

        <template v-slot:item.app_phone="{ item }">
                    <span v-if="item.salepoint_account && item.salepoint_account.salepoint_phone"
                          class="info--text">
                        {{ item.salepoint_account.salepoint_phone }}
                       </span>
          <span v-else>-</span>
        </template>

        <template v-slot:item.points="{ item }">
          <v-chip v-if="item.salepoint_account" color="blue" dark small>
            {{ item.salepoint_account.points | toCurrency }}
          </v-chip>
          <v-chip v-else color="blue" dark disabled small>
            0.00
          </v-chip>
        </template>

      </v-data-table>

      <v-card-actions>
        <div>
          <v-select v-model="per_page"
                    :items="[10,20,50,100,200,300,400,500]"
                    :style="{width: '120px'}"
                    dense
                    hide-details
                    label="Ligne par page"
                    outlined></v-select>
        </div>
        <v-spacer/>
        <v-pagination v-if="total>per_page"
                      v-model="pagination.current"
                      :length="pagination.total"
                      circle
                      total-visible="6"
                      @input="onPageChange"
        ></v-pagination>
      </v-card-actions>

    </div>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResult from "../../../components/NoResult";

export default {
   props: ['task'],
   components: {NoResult},
   data() {
      return {
         FILE_URL: process.env.VUE_APP_FILE_URL,
         loading: false,

         salepointIds: [],
         selected: [],
         salepoints: [],

         total: 0,
         per_page: 10,
         pagination: {
            current: 1,
            total: 0
         },
         headers: [
            {text: 'Photo', value: 'photo'},
            {text: this.$store.state.keyword.key_26, value: 'code'},
            {text: this.$store.state.keyword.key_27, value: 'name'},
            {text: this.$store.state.keyword.key_1, value: 'type'},
            {text: this.$store.state.keyword.key_28, value: 'phone'},
            {text: 'Téléphone-APP', value: 'app_phone'},
            {text: this.$store.state.keyword.key_3, value: 'city'},
            {text: 'Total des points', value: 'points'},
         ],

         filter_salepoint_ids: []
      }
   },
   methods: {
      selectRow(slot) {

         let id = slot.item.id

         if (!this.salepointIds.includes(id)) {
            this.salepointIds.push(id)
         } else {
            const index = this.salepointIds.indexOf(id)
            this.salepointIds.splice(index, 1)
         }

         this.task.salepoint_ids = this.salepointIds
      },
      selectAllRow(slot) {

         let array = slot.items

         if (slot.value) {
            array.forEach(item => {
               this.salepointIds.push(item.id)
            })
            this.salepointIds = [...new Set(this.salepointIds)]
         } else {
            array.forEach(item => {
               const index = this.salepointIds.indexOf(item.id)
               this.salepointIds.splice(index, 1)
            })
         }

         this.task.salepoint_ids = this.salepointIds
      },
      getAffectedSalepoints(salepointIds, currant, filter_salepoint_ids) {

         this.filter_salepoint_ids = filter_salepoint_ids ? filter_salepoint_ids : this.filter_salepoint_ids

         this.pagination.current = currant ? currant : this.pagination.current
         this.loading = true
         this.$Progress.start()
         HTTP.get('/campaigns/salepoints/affected?page=' + this.pagination.current, {
            params: {
               per_page: this.per_page,
               filter_salepoint_ids: this.filter_salepoint_ids
            }
         }).then((res) => {
            this.loading = false
            this.$Progress.finish()
            this.salepoints = res.data.data.data
            this.pagination.current = res.data.data.current_page;
            this.pagination.total = res.data.data.last_page;
            this.total = res.data.data.total;

            /***
             * Check selected
             */

            if (salepointIds) {

               this.salepointIds = salepointIds
               this.selected = this.salepoints.filter(function (item) {
                  return salepointIds.includes(item.id)
               });

            } else {
               let _vm = this
               this.selected = this.salepoints.filter(function (item) {
                  return _vm.salepointIds.includes(item.id)
               });
            }

         }).catch(err => {
            this.$Progress.fail()
            this.loading = false
            console.log(err)
         })
      },
      onPageChange() {
         this.getAffectedSalepoints();
      },
      reset() {
         this.filter_salepoint_ids = []
         this.pagination.current = 1
         this.getAffectedSalepoints()
      }
   },
   computed: {
      tasks() {
         return this.$store.state.campaign.tasks;
      }
   },
   watch: {
      per_page() {
         this.pagination.current = 1
         this.getAffectedSalepoints();
      },
   }
}
</script>

<style scoped>

</style>